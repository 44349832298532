body > #root {
  min-height: 100vh; /* will cover the 100% of viewport */
  display: flex;
  position: relative;
  flex-flow: column;
}
#bo-users-sectors ~ svg,
#events_interested ~ svg,
#isPortfolioDraft ~ svg,
#isPoortfolioPublished ~ svg {
  display: none;
}

a:focus-visible {
  outline: 4px dashed !important;
  outline-color: blue !important;
  outline-offset: -4px;
}
button:focus-visible {
  outline: 4px dashed !important;
  outline-color: blue !important;
  outline-offset: -8px;
}

.MuiPopover-paper.MuiPaper-rounded {
  border-radius: 0 !important;
}

.MuiFormLabel-root.Mui-focused {
  color: black;
}

input[type='number']::-webkit-outer-spin-button,
input[type='number']::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type='number'] {
  -moz-appearance: textfield !important;
  margin: 0;
}

input[type='number']::-o-outer-spin-button,
input[type='number']::-o-inner-spin-button {
  -o-appearance: none;
  margin: 0;
}

input[type='number']::-ms-outer-spin-button,
input[type='number']::-ms-inner-spin-button {
  -ms-appearance: none;
  margin: 0;
}

fieldset {
  border: none;
  padding: 0;
  margin: 0;
}

@media print {
  header {
    display: none !important;
  }
}

@media (any-hover: none) and (max-width: 768px) {
  .portfolioList > .slick-list {
    overflow-x: scroll !important;
    padding-bottom: 17px !important;
  }
  .portfolioList > .slick-list > .slick-track {
    width: calc(170px * 24) !important;
    transform: translate3d(0, 0, 0) !important;
  }
  .portfolioListHome > .slick-list {
    overflow-x: scroll !important;
    padding-bottom: 17px !important;
  }
  .portfolioListHome > .slick-list > .slick-track {
    width: calc(170px * 24) !important;
    transform: translate3d(0, 0, 0) !important;
  }
}

#main {
  min-height: calc(100vh - 64px - 174px);
}

@media (min-width: 0px) and (max-width: 600px) {
  #main {
    min-height: calc(100vh - 64px - 350px);
  }
}

#portfolios {
  height: 400px;
}

/* clears the ‘X’ from Internet Explorer */
input[type='search']::-ms-clear {
  display: none;
  width: 0;
  height: 0;
}
input[type='search']::-ms-reveal {
  display: none;
  width: 0;
  height: 0;
}
/* clears the ‘X’ from Chrome */
input[type='search']::-webkit-search-decoration,
input[type='search']::-webkit-search-cancel-button,
input[type='search']::-webkit-search-results-button,
input[type='search']::-webkit-search-results-decoration {
  display: none;
}

.inactiveIcon {
  opacity: 0.5;
}

.activeIcon {
  -webkit-filter: drop-shadow(0px 5px 5px #262626);
  filter: drop-shadow(0px 5px 5px #262626);
}

.MuiCalendarPicker-root .MuiTypography-root.MuiTypography-caption {
  margin: 0;
}
