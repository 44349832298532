.skipLinks ul {
  list-style: none;
  margin: 0;
  padding: 0;
  width: auto;
  position: absolute;
  z-index: 1000;
  float: left;
  position: relative;
  display: block;
  width: 100%;
}

.skipLinks ul li {
  float: left;
  position: relative;
  width: 100%;
  display: block;
  height: 100%;
}

.skipLinks a {
  position: absolute;
  left: -10000px;
  width: 1px;
  height: 1px;
  overflow: hidden;
  padding: 16px
}

.skipLinks a:focus-visible,
.skipLinks a:focus,
.skipLinks a:active {
  left: 0;
  height: auto;
  width: 100%;
  overflow: visible;
  text-align: center;
  background: white;
  color:black;
  /* text-decoration: none; */
}